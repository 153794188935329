import {
  OpenOrgEvent,
  OpenOrgEventRegistration,
  OpenOrgEventRegistrationId,
  OpenOrgEventRegistrationNote,
  OrgId
} from "@ollie-sports/models";
import moment from "moment-timezone";

const LINK_REGEX =
  /\b((https?:\/\/)?(([-a-z0-9_]{2,50}\.))+(com|org|net|info|biz|us|co|io|xyz|top|club|online|site|app|gov|edu|int|mobi|pro|shop|store|tech|website|space|design|tv|me|ai|cc|ca|eu|uk|de|fr|es|it|nl|au|ru|in|ch|se|fi|pl|be|co\.uk|asia|ac|travel|tel|tk|la|name|co\.nz|org\.uk|nu|co\.za|live|news|cn|jp|br|mx|ar|ir|pe|co\.il|gr|hk|kr|sg|tw|th|tr|ae|id|ph|qa|vn|arpa|cat|jobs|pro|coop|post)[^\w\s]?([-a-zA-Z0-9()@:%_\+.~#?$!&/=]*))(\b)?/gim;

import { getServerHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import express from "express";
import { sendTwilioSMS } from "../../utils/twilio-sdk";
import { dateFormatters, translate } from "@ollie-sports/i18n";
import { getRegistrationStatus, sanitizeEmailMessageByRemovingHTMLElements, sendOrgEmail, setKeyValueStore } from "../../utils";
import { linkify } from "../../utils/linkify";
import { olliePipe__server__sendOlliePipeEvent } from "../olliePipe";

export async function openOrgEventRegistrations__server__sendTeamInvite(p: {
  openOrgEventRegistrationID: OpenOrgEventRegistrationId;
  teamId: string;
  subject: string;
  selfAccountId: string;
  expireHours: number;
  welcomeText: string;
  replyToEmail: string;
  replyToName: string;
  orgId: OrgId;
}) {
  const completedActions = { saveToDB: false, sentEmail: false, sentText: false };
  const { appOllieFirestoreV2: h, serverConfig } = getServerHelpers();

  const [existingRegistration, team, sender, org] = await Promise.all([
    h.OpenOrgEventRegistration.getDoc(p.openOrgEventRegistrationID),
    h.Team.getDoc(p.teamId),
    h.Account.getDoc(p.selfAccountId),
    h.Org.getDoc(p.orgId)
  ]);

  if (!team || (!team.accounts[p.selfAccountId] && !org?.accounts[p.selfAccountId]) || !sender) {
    throw new Error("Unable to send invites for this team id! " + p.teamId);
  }

  if (!existingRegistration) {
    throw new Error("Unable to find openOrgEventRegistrationId! " + p.openOrgEventRegistrationID);
  }

  const inviteUrl = `${serverConfig.httpWebappRoot}/tryouts/respond/${team.id}/${existingRegistration.id}`;

  try {
    const openOrgEvent = await h.OpenOrgEvent.getDoc(existingRegistration.openOrgEventId);

    if (!openOrgEvent) {
      throw new Error("Unable to find openOrgEvent! " + existingRegistration.openOrgEventId);
    }

    const registrationStatus = getRegistrationStatus(existingRegistration.tryoutInfo.actions || [], p.teamId);

    if (registrationStatus === "invite-pending") {
      throw new Error("Unable to send an invite to a registrant with a pending invite!");
    }

    if (registrationStatus === "invite-accepted" || registrationStatus === "manual-player-accept") {
      throw new Error("Unable to send an invite to a registrant who has already accepted your team's invite!");
    }

    const expiresAtMS = Date.now() + p.expireHours * 1000 * 60 * 60;

    await h.OpenOrgEventRegistration.updateShallow({
      id: p.openOrgEventRegistrationID,
      doc: {
        tryoutInfo: {
          ...existingRegistration.tryoutInfo,
          actions: [
            ...(existingRegistration.tryoutInfo.actions || []),
            {
              type: "invite",
              createdAtMS: Date.now(),
              teamId: p.teamId,
              triggeredByAccountId: p.selfAccountId,
              actionUrl: inviteUrl,
              expiresAtMS
            }
          ]
        }
      }
    });

    completedActions.saveToDB = true;

    const cleanedWelcomeText = sanitizeEmailMessageByRemovingHTMLElements(p.welcomeText);
    const expireMoment = moment.tz(expiresAtMS, openOrgEvent.timezone);
    const serverLocale = existingRegistration.locale ?? "en-us";

    const pleaseAcceptBelowLinkStr = translate(
      {
        defaultMessage:
          "Please click the link below to accept or decline your invitation before it expires in {numHours} hours on {expireDate}.",
        serverLocale
      },
      {
        expireDate: dateFormatters.mmmm_d_yyyy_t_tt_a(expireMoment, serverLocale) + " " + expireMoment.format("z"),
        numHours: p.expireHours
      }
    );

    await Promise.all([
      sendTwilioSMS({
        body: translate(
          {
            defaultMessage:
              "{playerFirstName} was invited to join {teamShortName}! Please check your email, including your Junk folder.",
            serverLocale
          },
          {
            playerFirstName: existingRegistration.playerInfo.firstName.trim(),
            teamShortName: team.name.trim()
          }
        ),
        to: existingRegistration.guardianContactInfo.phoneNumber
      })
        .then(() => {
          completedActions.sentText = true;
        })
        .catch(async e => {
          if (e?.status?.toString().match(/4\d\d/)) {
            //Do nothing... Invalid phone number
          } else {
            await new Promise(res => setTimeout(res, 500)); //Give the other request chance to return
            throw e;
          }
        }),
      sendOrgEmail({
        orgId: existingRegistration.orgId,
        replyToEmailAddress: p.replyToEmail,
        replyToName: p.replyToName,
        personalizations: [
          {
            email: existingRegistration.guardianContactInfo.email,
            message: linkify(
              `${cleanedWelcomeText}<br/><br/><p>${pleaseAcceptBelowLinkStr}</p><p style="text-align:center">${inviteUrl}</p>`
            ),
            name: existingRegistration.guardianContactInfo.firstName,
            subject: p.subject
          }
        ]
      })
        .then(() => {
          completedActions.sentEmail = true;
        })
        .catch(async e => {
          if (e?.code?.toString().match(/4\d\d/)) {
            //Do nothing... Invalid phone email
          } else {
            await new Promise(res => setTimeout(res, 500)); //Give the other request chance to return
            throw e;
          }
        })
    ]);

    return {
      success: true,
      inviteUrl,
      ...completedActions
    };
  } catch (e) {
    const payload = {
      error: (e as any)?.message,
      triggeringAccountId: p.selfAccountId,
      teamId: p.teamId,
      openOrgEventRegistrationId: p.openOrgEventRegistrationID
    };
    await olliePipe__server__sendOlliePipeEvent({
      type: "error-problem-sending-tryout-invite",
      payload,
      slackImportantErrorsMessage: `Unable to properly send tryout invitation. ${JSON.stringify(payload, null, 2)}`
    });

    return {
      success: completedActions.saveToDB,
      inviteUrl,
      ...completedActions
    };
  }
}

openOrgEventRegistrations__server__sendTeamInvite.auth = async (r: express.Request) => {
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};
