import { BatchRunner } from "@ollie-sports/firebase-lift/dist/BatchRunner";
import { dateFormatters, translate } from "@ollie-sports/i18n";
import {
  AllCollectionNames,
  CouponType,
  OpenOrgEvent,
  OpenOrgEventRegistration,
  OpenOrgEventSession,
  Org,
  OrgCoupon,
  OrgCouponId,
  OrgPaymentOpenOrgEvent,
  OrgPaymentType
} from "@ollie-sports/models";
import moment from "moment";
import { locale } from "moment";
import { SendMessageToSlackChannel, api, generateOrgPaymentId, sendOrgEmail } from "../..";
import { getVerboseLocationString, getLocationURL, payments } from "../../compute";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import { forceHttps } from "../../utils/url-helpers";
import { common__hashObject } from "../common.api";
import { olliePipe__server__sendOlliePipeEvent } from "../olliePipe";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import { linkify } from "../../utils/linkify";

export async function openOrgEventRegistrations__server__createRegistration(
  this: ServerThisContext,
  p: {
    registration: Omit<OpenOrgEventRegistration, "id" | "updatedAtMS" | "createdAtMS">;
    locale: string;
    paymentInfo: {
      name: string;
      orgCouponId?: string;
      cardTokenId?: string;
      email: string;
      phoneNumber?: string;
    };
  }
) {
  let hasPaid = false;
  const {
    appOllieFirestoreV2: h,
    injectedServerLibraries: { stripe, sendGrid },
    channels
  } = getServerHelpers();
  const { olliePipe } = getUniversalHelpers();

  try {
    const [event, org, coupon, orgSettings] = await Promise.all([
      h.OpenOrgEvent.getDoc(p.registration.openOrgEventId),
      h.Org.getDoc(p.registration.orgId),
      p.paymentInfo?.orgCouponId ? h.OrgCoupon.getDoc(p.paymentInfo?.orgCouponId) : undefined,
      h.OrgSettings.getDoc(p.registration.orgId)
    ]);

    if (!event) {
      throw new Error("Could not register for a tryout event that does not exist.");
    }

    if (!org) {
      throw new Error("Could not find the org for the event.");
    }

    if (event.orgId !== org.id) {
      throw new Error("The event org id does not match the org id on the registration.");
    }

    if (p.paymentInfo?.orgCouponId && !coupon) {
      throw new Error("Unable to find coupon");
    }

    const session = event.sessions?.find(
      s =>
        !!s.birthYears.includes(p.registration.tryoutInfo.sessionSelection.birthYear) &&
        !!s.genders.includes(p.registration.tryoutInfo.sessionSelection.gender)
    );

    if (!session) {
      throw new Error("Could not find the selected session.");
    }

    let receiptUrl: string | null = null;
    let amountToCharge = 0;
    if (event.feeAmountUSD) {
      amountToCharge = payments.getTotalAmountToCharge({
        appliedCoupon: coupon || undefined,
        pricingInfo: {
          displayString: "",
          numberCents: Number(event.feeAmountUSD) * 100,
          chargeStripeFees: event.chargeProcessingFees,
          applicationFeeAmount: 0
        }
      });

      if (amountToCharge) {
        if (!p.paymentInfo?.cardTokenId) {
          throw new Error("No card token info found! Aborting");
        }

        const { guardianContactInfo, playerInfo, tryoutInfo } = p.registration;

        const result = await stripe.charges
          .create(
            {
              amount: amountToCharge,
              source: p.paymentInfo!.cardTokenId,
              description: "Registration Payment",
              currency: "usd",
              metadata: {
                eventTitle: event.title,
                eventId: event.id,
                session: Object.values(tryoutInfo.sessionSelection).join(" | "),
                guardian: `${guardianContactInfo.firstName} ${guardianContactInfo.lastName} <${guardianContactInfo.email}> ${guardianContactInfo.phoneNumber}`,
                player: `${playerInfo.firstName} ${playerInfo.lastName} (${playerInfo.dateOfBirth} ${playerInfo.gender})`
              }
            },
            {
              stripeAccount: org.stripeAccountId,
              idempotencyKey: common__hashObject({ obj: { ...p.registration, token: p.paymentInfo.cardTokenId } })
            }
          )
          .then(c => {
            return { status: "success", charge: c } as const;
          })
          .catch(async e => {
            let errorMsg = this.translate({
              defaultMessage: "There was a problem charging your card. Please try again or contact support@olliesports.com",
              serverLocale: p.locale
            });
            if (e?.raw?.code || e?.raw?.decline_code) {
              switch (e?.raw?.decline_code || e?.raw?.code) {
                case "card_declined":
                  errorMsg = this.translate({
                    defaultMessage: "Your card was declined.",
                    serverLocale: p.locale
                  });
                  break;
                case "expired_card":
                  errorMsg = this.translate({
                    defaultMessage: "Your card has expired.",
                    serverLocale: p.locale
                  });
                  break;
                case "incorrect_cvc":
                  errorMsg = this.translate({
                    defaultMessage: "Incorrect CVC.",
                    serverLocale: p.locale
                  });
                  break;
                case "lost_card":
                  errorMsg = this.translate({
                    defaultMessage: "Card reported lost.",
                    serverLocale: p.locale
                  });
                  break;
                case "stolen_card":
                  errorMsg = this.translate({
                    defaultMessage: "Card reported stolen.",
                    serverLocale: p.locale
                  });
                  break;
                case "incorrect_number":
                  errorMsg = this.translate({
                    defaultMessage: "Incorrect card number.",
                    serverLocale: p.locale
                  });
                  break;
                case "incorrect_zip":
                  errorMsg = this.translate({
                    defaultMessage: "Incorrect zip code.",
                    serverLocale: p.locale
                  });
                  break;
                case "insufficient_funds":
                  errorMsg = this.translate({
                    defaultMessage: "Insufficient funds.",
                    serverLocale: p.locale
                  });
                  break;
                case "invalid_number":
                  errorMsg = this.translate({
                    defaultMessage: "Invalid Card Number.",
                    serverLocale: p.locale
                  });
                  break;
                default:
                  errorMsg = this.translate({
                    defaultMessage: "Your card was declined.",
                    serverLocale: p.locale
                  });
              }
              errorMsg =
                errorMsg +
                " " +
                this.translate({ defaultMessage: "Please update your information and try again.", serverLocale: p.locale });
            } else {
              return Promise.reject(e);
            }

            return { status: "error", errorMsg: errorMsg } as const;
          });

        hasPaid = true;

        if (result.status === "error") {
          await olliePipe__server__sendOlliePipeEvent({
            type: "metric-tryout-payment-failure",
            payload: {
              errorMsg: result.errorMsg,
              ...p.paymentInfo,
              ...p.registration.guardianContactInfo,
              ...p.registration.playerInfo
            }
          });
          return result;
        }

        receiptUrl = result.charge.receipt_url;
      }
    }

    const registrationToWrite = { ...p.registration };

    const writeRegistration = async () => {
      const now = Date.now();
      await h._BatchRunner.executeBatch(
        await Promise.all([
          h.OpenOrgEventRegistration.add(
            {
              doc: {
                ...registrationToWrite,
                locale: p.locale,
                updatedAtMS: now,
                createdAtMS: now,
                paymentInfo: { amountPaid: amountToCharge, couponCodeUsed: coupon?.code }
              }
            },
            { returnBatchTask: true }
          ),
          h.OpenOrgEvent.update(
            {
              id: event.id,
              doc: {
                answeredCustomSectionIds: {
                  ...event.answeredCustomSectionIds,
                  ...registrationToWrite.customSectionResponses?.reduce((acc, val) => {
                    acc[val.customSectionId] = true;
                    return acc;
                  }, {} as Record<string, true>)
                },
                lastRegistrationAtMS: Date.now()
              }
            },
            { returnBatchTask: true }
          )
        ])
      );
    };

    await writeRegistration()
      .catch(async () => {
        await new Promise(res => setTimeout(res, 1000));
        return writeRegistration();
      })
      .catch(async () => {
        await new Promise(res => setTimeout(res, 5000));
        return writeRegistration();
      });

    const locationString = getVerboseLocationString(session.location, "en-us") ?? "";
    const locationURL = getLocationURL(session.location) ?? "";
    const replyToEmail =
      orgSettings?.tryoutSettings?.defaultReplyToEmailAddress ??
      orgSettings?.defaultReplyToEmailAddress ??
      "noreply@olliesports.com";

    const linkifiedMessage = orgSettings?.tryoutSettings?.confirmationEmailMessage
      ? linkify(orgSettings.tryoutSettings.confirmationEmailMessage).replace(/\n/g, "<br>")
      : undefined;

    try {
      await sendGrid.send({
        from: `${org.name} <noreply@olliesports.com>`,
        to: p.registration.guardianContactInfo.email.replace(/\s/g, ""),
        templateId: "d-a5c31148b150476b9032eda02efddc90",
        replyTo: replyToEmail.replace(/\s/g, ""),
        dynamicTemplateData: {
          logoUrl: org.logoUri ?? "https://uploads-ssl.webflow.com/5fa4404d44804735e5377f58/61031ccc7d0bab06ee2ca3f6_ollie.png",
          facebookUrl: orgSettings?.socialUrls?.facebookUrl ? forceHttps(orgSettings.socialUrls.facebookUrl) : undefined,
          instagramUrl: orgSettings?.socialUrls?.instagramUrl ? forceHttps(orgSettings.socialUrls.instagramUrl) : undefined,
          twitterUrl: orgSettings?.socialUrls?.twitterUrl ? forceHttps(orgSettings.socialUrls.twitterUrl) : undefined,
          logoLinkUrl: orgSettings?.websiteUrl ? forceHttps(orgSettings?.websiteUrl) : undefined,
          name: p.registration.guardianContactInfo.firstName,
          playerName: `${p.registration.playerInfo.firstName} ${p.registration.playerInfo.lastName}`,
          eventTitle: event.title,
          locationString,
          subject: translate({ defaultMessage: "Confirmation", serverLocale: p.locale }) + ": " + event.title,
          locationURL,
          message: linkifiedMessage,
          sessionLocationDetails: session.locationDetails,
          receiptURL: receiptUrl ?? undefined,
          primaryContactName: event.pointOfContact?.name,
          primaryContactEmail: event.pointOfContact?.email,
          primaryContactPhone: event.pointOfContact?.phoneNumber,
          eventDatesAndTimes: session.sessionTimes
            .map(time => {
              return `${moment(`${time.date}T${time.startTime}`).format("dddd, MMMM Do YYYY, h:mm A")} - ${moment(
                `${time.date}T${time.endTime}`
              ).format("hh:mm A")}`;
            })
            .join("<br>")
        },
        mailSettings: {
          bypassListManagement: {
            enable: true
          }
        }
      });
    } catch (e) {
      await olliePipe.emitEvent({
        type: "metric-open-org-event-confimation-email-failure",
        payload: {
          orgName: org.name,
          email: p.registration.guardianContactInfo.email,
          name: p.registration.guardianContactInfo.firstName + " " + p.registration.guardianContactInfo.lastName
        }
      });
    }
    return { status: "success" } as const;
  } catch (e) {
    SendMessageToSlackChannel({
      channel: "#important-errors",
      message: `
      Unexpected Error Processing Registration!
      OrgId: ${p.registration.orgId}
      Payload: ${JSON.stringify(p, null, 2)}
      Message: ${"message" in (e as any) ? (e as any).message : "No Message"}
    `
    });

    if (hasPaid) {
      await olliePipe__server__sendOlliePipeEvent({ type: "error-registration-failure-after-pay", payload: e });
    } else {
      await olliePipe__server__sendOlliePipeEvent({ type: "error-registration-failure-without-payment", payload: e });
    }

    const errorMsg = hasPaid
      ? translate({
          defaultMessage:
            "Your charge was completed but registration failed. DO NOT SUBMIT AGAIN. Please email nate@olliesports.com so we can get you taken care of!",
          serverLocale: p.locale
        })
      : translate({
          defaultMessage:
            "There was a problem registering. Please make sure that you have completed all of the sections. Every section should have a check mark. Make sure to fill out all of the information and then try again. If the issue persists, please contact nate@olliesports.com",
          serverLocale: p.locale
        });

    return {
      status: "error",
      errorMsg
    } as const;
  }
}

openOrgEventRegistrations__server__createRegistration.auth = () => {};
